import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Rate, Form, Input, List, Pagination, message } from 'antd';
import { API_BASE_URL } from '../config';

type Feedback = {
  username: string;
  text: string;
  rating: number;
  date_created: string;
};

const GameDetail: React.FC = () => {
  const location = useLocation();
  const { id, title, description, imgSrc, price, rating } = location.state as {
    id: number;
    title: string;
    description: string;
    imgSrc: string;
    price: number;
    rating: number;
  };

  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalFeedbacks, setTotalFeedbacks] = useState(0);
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [form] = Form.useForm();

  // Fetch feedbacks from the API with pagination
  const fetchFeedbacks = async (page: number, size: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/games/${id}/feedbacks?page=${page}&page_size=${size}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch feedbacks');
      }

      const data = await response.json();
      setFeedbacks(data);
    } catch (error) {
      message.error('Failed to load feedbacks');
    }
  };

  // Handle form submission to submit feedback
  const handleSubmit = async (values: { comment: string; rating: number }) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/games/${id}/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({
          text: values.comment,
          rating: values.rating,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit feedback');
      }

      // Fetch new feedback list after submission
      fetchFeedbacks(currentPage, pageSize);
      form.resetFields();

      message.success('Feedback submitted successfully');
    } catch (error) {
      message.error('Failed to submit feedback');
    }
  };

  // Handle page change for pagination
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    fetchFeedbacks(page, pageSize);
  };

  useEffect(() => {
    // Function to fetch game count
    const fetchFeedbackCount = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/games/${id}/feedbacks/count`); // Call the API
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        setTotalFeedbacks(data.count);
      } catch (err) {
        console.log(err)
      }
    };

    fetchFeedbackCount(); // Invoke the function
  }, []);

  // Fetch feedbacks when the component mounts or when page changes
  useEffect(() => {
    fetchFeedbacks(currentPage, pageSize);
  }, [currentPage, pageSize]);

  return (
    <div style={{ padding: '20px' }}>
      <h1>{title}</h1>
      <img src={imgSrc} alt={title} width={210} />
      <p>{description}</p>
      <p>Price: ${price.toFixed(2)}</p>
      <Rate disabled defaultValue={rating} />

      <div style={{ marginTop: '20px' }}>
        <h2>Customer Feedback</h2>

        {/* Feedback Form */}
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item name="comment" label="Comment" rules={[{ required: true }]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="rating" label="Rating" rules={[{ required: true }]}>
            <Rate />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit Feedback
            </Button>
          </Form.Item>
        </Form>

        {/* Display feedback */}
        <List
          header={`${feedbacks?.length || 0} Reviews`}
          dataSource={feedbacks}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={<b>{item.username}</b>}
                description={
                  <>
                    <Rate disabled defaultValue={item.rating} />
                    <p>{item.text}</p>
                    <small>{new Date(item.date_created).toLocaleString()}</small>
                  </>
                }
              />
            </List.Item>
          )}
        />

        {/* Pagination */}
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={totalFeedbacks}
          onChange={handlePageChange}
          showSizeChanger={false}
          style={{ textAlign: 'center', marginTop: '20px' }}
        />
      </div>
    </div>
  );
};

export default GameDetail;
