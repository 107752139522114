import React, { useEffect, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Button, Form, Spin, Alert } from 'antd';
import { API_BASE_URL } from '../config';

type CheckoutFormProps = {
  price: number;
  rentalId: string;
};

const CheckoutForm: React.FC<CheckoutFormProps> = ({ price, rentalId }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [requestId, setRequestId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [accountData, setAccountData] = useState<any | null>(null);
  const [isSteamGuardRequested, setIsSteamGuardRequested] = useState(false);
  const [steamGuardCode, setSteamGuardCode] = useState<string | null>(null);  // New state for the code

  useEffect(() => {
    async function fetchClientSecret() {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/payments/payment-intent/${rentalId}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        });

        if (response.status >= 400) {
          setError('There was an error creating payment, please try again');
          return null;
        }

        const data = await response.json();
        setClientSecret(data.clientSecret); // Store client secret in state
      } catch (err) {
        setError("Failed to initialize payment. Please try again.");
      }
    }

    fetchClientSecret();
  }, [price, rentalId]);

  const verifyPayment = async (paymentIntentId: string) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/payments/verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({"rental_id": rentalId,
                              "payment_intend_id": paymentIntentId}),
      });

      const data = await response.json();
      if (response.ok) {
        setAccountData(data); // Store user data if verification is successful
      } else {
        setError("Payment verification failed. Please contact our support if your funds have been withdrawn");
      }
    } catch (err) {
      setError("Failed to verify payment. Please try again or contact our support.");
    }
  };

  const sendSteamGuardRequest = async () => {
    if (!accountData) {
      setError("Account data is missing, unable to request Steam Guard code.");
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/game-accounts/${accountData.steam_id_64}/me/request-steam-guard-code`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        }
      });

      const data = await response.json();
      if (response.ok) {
        setRequestId(data.request_id);
        setIsSteamGuardRequested(true); // Mark that the Steam Guard request was sent
        await checkCodeStatus(data.request_id, accountData.steam_id_64)
      } else {
        setError("Error sending request for Steam Guard code. Please try again in your orders or contact support");
      }
    } catch (err) {
      setError("Failed to send request for Steam Guard code. Please try again or contact our support.");
    }
  };

  // Long polling for checking Steam Guard code status
  const checkCodeStatus = async (request_id: string, account_id: string) => {
    const interval = setInterval(async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/steam-guard/${request_id}/status`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${localStorage.getItem('authToken')}`,
          },
        });

        if (response.status === 200) {
          clearInterval(interval); // Stop polling when the code is completed
          await getSteamGuardCode(request_id, account_id); // Fetch the completed code
        }
      } catch (err) {
        setError("Failed to check Steam Guard status. Please try again or contact support.");
        clearInterval(interval); // Stop polling on error
      }
    }, 2000); // Poll every 2 seconds
  };

  // Get the completed Steam Guard code
  const getSteamGuardCode = async (request_id: string, account_id: string) => {
    if (!accountData) {
      setError("Account data is missing, unable to fetch Steam Guard code.");
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/steam-guard/${request_id}/me?account_id=${account_id}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      const data = await response.json();
      if (response.ok) {
        setSteamGuardCode(data.code); // Set the Steam Guard code in state
      } else {
        setError("Error fetching Steam Guard code. Please try again or contact support.");
      }
    } catch (err) {
      setError("Failed to retrieve Steam Guard code. Please try again or contact our support.");
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    if (!stripe || !elements || !clientSecret) {
      setError("Stripe has not loaded yet. Please try again.");
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) return;

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: { card: cardElement },
    });

    if (error) {
      setError(error.message || "Payment failed.");
    } else if (paymentIntent?.status === "succeeded") {
      setSuccess(true);
      await verifyPayment(paymentIntent.id); // Verify payment on backend after success
    }

    setLoading(false);
  };

  return (
    <Form onFinish={handleSubmit}>
      <Form.Item>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': { color: '#aab7c4' },
              },
              invalid: { color: '#9e2146' },
            },
          }}
        />
      </Form.Item>
      {error && <Alert message={error} type="error" />}
      {success ? (
        accountData ? (
          <>
            <Alert message="Payment succeeded!" description={`Account details (Account name: ${accountData.account_name}, Account password: ${accountData.password})`} type="success" />
            {!isSteamGuardRequested && (
              <Button type="primary" onClick={sendSteamGuardRequest}>
                Request Steam Guard Code
              </Button>
            )}
            {isSteamGuardRequested && !steamGuardCode && <Spin tip="Waiting for Steam Guard Code..." />}
            {steamGuardCode && <Alert message="Steam Guard Code" description={`Your code: ${steamGuardCode}`} type="success" />}
          </>
        ) : (
          <Alert message="Payment succeeded!" description="Verifying payment..." type="info" />
        )
      ) : (
        <Button type="primary" htmlType="submit" loading={loading} disabled={!stripe || !elements || !clientSecret}>
          Pay ${price.toFixed(2)}
        </Button>
      )}
      {loading && <Spin />}
    </Form>
  );
};

export default CheckoutForm;
