import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';

const ConvertTempUser: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [formValues, setFormValues] = useState<any>({ email: '' });
  const navigate = useNavigate();

  const handleSendVerification = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/users/temp/send-verification`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: formValues.email }),
      });

      if (response.ok) {
        message.success('Verification email sent!');
        setEmailSent(true);
        const data = await response.json();
      } else {
        const errorData = await response.json();
        message.error('Failed to send verification email');
      }
    } catch (error) {
      console.log(error)
      message.error('An error occurred while sending the verification email');
    } finally {
      setLoading(false);
    }
  };

  const handleRegistration = async (values: {
    first_name?: string;
    last_name?: string;
    username: string;
    email: string;
    password: string;
    code: string;
  }) => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/register/convert-temp?code=${values.code}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        message.success('Registration successful!');
        navigate('/login');
      } else {
        const errorData = await response.json();

        message.error('Registration failed. Please try again.');
      }
    } catch (error) {
      message.error('An error occurred during registration.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: '400px', margin: '0 auto', padding: '20px' }}>
      <h2>{emailSent ? 'Complete Your Registration' : 'Enter Your Email'}</h2>
      <Form layout="vertical" onFinish={emailSent ? handleRegistration : handleSendVerification}>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please enter your email' }, { type: 'email', message: 'Please enter a valid email' }]}
        >
          <Input
            placeholder="Email"
            value={formValues.email}
            onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
            disabled={emailSent}
          />
        </Form.Item>

        {emailSent && (
          <>
            <Form.Item
              label="Code"
              name="code"
              rules={[{ required: true, message: 'Please enter the verification code' }]}
            >
              <Input placeholder="Verification Code" />
            </Form.Item>

            <Button type="link" onClick={handleSendVerification} loading={loading}>
              Resend Code
            </Button>

            <Form.Item label="First Name" name="first_name">
              <Input placeholder="First Name" />
            </Form.Item>

            <Form.Item label="Last Name" name="last_name">
              <Input placeholder="Last Name" />
            </Form.Item>

            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: 'Please enter your username' }]}
            >
              <Input placeholder="Username" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please enter your password' },
                {
                  pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/,
                  message: 'Password must be at least 8 characters long, with at least one uppercase letter, one lowercase letter, and one number.',
                },
              ]}
              hasFeedback
            >
              <Input.Password placeholder="Password" />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="confirm_password"
              dependencies={['password']}
              hasFeedback
              rules={[
                { required: true, message: 'Please confirm your password' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Passwords do not match'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm Password" />
            </Form.Item>
          </>
        )}

        <Button type="primary" htmlType="submit" loading={loading} block>
          {emailSent ? 'Complete Registration' : 'Send Verification'}
        </Button>
      </Form>
    </div>
  );
};

export default ConvertTempUser;
