import React, { useState, useEffect } from 'react';
import { Table, Tag, Button, Modal, message } from 'antd';
import { API_BASE_URL } from '../config';

// Define the type for original game data
type OriginalGameDataType = {
  title: string;
  genre: string;
  release_date: Date;
  price: number;
  description: string;
  game_img_url: string;
};

// Define the type for change requests based on API response
type ChangeRequest = {
  key: string;
  requestID: string;
  date: string;
  status: string;
  proposedChanges: Record<string, any>;
  moderator_id: string;
  game_id: number;
};

export const GameChangeRequests: React.FC = () => {
  const [changeRequests, setChangeRequests] = useState<ChangeRequest[]>([]);
  const [selectedRequest, setSelectedRequest] = useState<ChangeRequest | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [originalGameData, setOriginalGameData] = useState<OriginalGameDataType | null>(null); // State to hold original game data

  // Fetch moderator requests from the API
  useEffect(() => {
    const fetchModeratorRequests = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/admins/me/moderator-requests`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          },
        });
        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`);
        }
        const data = await response.json();
        const formattedData: ChangeRequest[] = data.map((request: any) => ({
          key: request.id.toString(),
          requestID: request.id.toString(),
          date: new Date(request.request_date).toLocaleDateString(),
          status: request.status.charAt(0).toUpperCase() + request.status.slice(1),
          proposedChanges: request.changes,
          moderator_id: request.moderator_id.toString(),
          game_id: request.game_id,
        }));
        setChangeRequests(formattedData);
      } catch (error) {
        console.error('Error fetching moderator requests:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchModeratorRequests();
  }, []);

  // Function to fetch original game data by game_id
  const fetchOriginalGameData = async (game_id: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/games/${game_id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      });
      if (!response.ok) {
        throw new Error(`Error fetching game data: ${response.statusText}`);
      }
      const data = await response.json();
      setOriginalGameData(data);
    } catch (error) {
      console.error('Error fetching original game data:', error);
      message.error('Failed to fetch original game data.');
    }
  };

  const handleRequestClick = (request: ChangeRequest) => {
    setSelectedRequest(request);
    setIsModalVisible(true);
    fetchOriginalGameData(request.game_id); // Fetch the original game data when the modal opens
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedRequest(null);
    setOriginalGameData(null); // Reset original game data
  };

  const handleApprove = async () => {
    if (selectedRequest) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/admins/me/moderator-requests/${selectedRequest.requestID}/approve`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          },
        });

        if (response.ok) {
          message.success(`Request ${selectedRequest.requestID} approved successfully.`);
          setChangeRequests((prev) =>
            prev.map((req) =>
              req.requestID === selectedRequest.requestID ? { ...req, status: 'Approved' } : req
            )
          );
        } else {
          throw new Error('Failed to approve the request.');
        }
      } catch (error) {
        console.error('Error approving request:', error);
        message.error('Failed to approve the request.');
      } finally {
        handleCloseModal();
      }
    }
  };

  const handleDisapprove = async () => {
    if (selectedRequest) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/admins/me/moderator-requests/${selectedRequest.requestID}/disapprove`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          },
        });

        if (response.ok) {
          message.success(`Request ${selectedRequest.requestID} disapproved successfully.`);
          setChangeRequests((prev) =>
            prev.map((req) =>
              req.requestID === selectedRequest.requestID ? { ...req, status: 'Disapproved' } : req
            )
          );
        } else {
          throw new Error('Failed to disapprove the request.');
        }
      } catch (error) {
        console.error('Error disapproving request:', error);
        message.error('Failed to disapprove the request.');
      } finally {
        handleCloseModal();
      }
    }
  };

  const columns = [
    {
      title: 'Request ID',
      dataIndex: 'requestID',
      key: 'requestID',
      render: (requestID: string, record: ChangeRequest) => (
        <Button type="link" onClick={() => handleRequestClick(record)}>
          {requestID}
        </Button>
      ),
    },
    {
      title: 'Moderator ID',
      dataIndex: 'moderator_id',
      key: 'moderator_id',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        let color = status === 'Pending' ? 'orange' : status === 'Approved' ? 'green' : 'red';
        return <Tag color={color}>{status.toUpperCase()}</Tag>;
      },
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={changeRequests} loading={loading} />

      <Modal
        title={`Comparison for Request ${selectedRequest?.requestID}`}
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={
          selectedRequest?.status === 'Pending' ? (
            [
              <Button key="disapprove" onClick={handleDisapprove}>
                Disapprove
              </Button>,
              <Button key="approve" type="primary" onClick={handleApprove}>
                Approve
              </Button>,
            ]
          ) : null
        }
        style={{ backdropFilter: 'blur(10px)' }}
        width={800}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
          {/* Original Data Section */}
          <div style={{ flex: 1, paddingRight: '10px', maxWidth: '400px', overflow: 'auto' }}>
            <h3>Original Data</h3>
            {originalGameData ? (
              <>
                <pre>
                  {`Title: ${originalGameData.title || 'N/A'}`}
                  {`\nGenre: ${originalGameData.genre || 'N/A'}`}
                  {`\nRelease Date: ${originalGameData.release_date || 'N/A'}`}
                  {`\nDescription: ${originalGameData.description || 'N/A'}`}
                  {`\nPrice: $${originalGameData.price || 'N/A'}`}
                </pre>
                {originalGameData.game_img_url && (
                  <img
                    src={originalGameData.game_img_url}
                    alt="Original Game"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '200px',
                      marginTop: '10px',
                      borderRadius: '8px',
                    }}
                  />
                )}
              </>
            ) : (
              'Loading original data...'
            )}
          </div>

          {/* Proposed Changes Section */}
          <div style={{ flex: 1, paddingLeft: '10px', maxWidth: '400px', overflow: 'auto' }}>
            <h3>Proposed Changes</h3>
            {selectedRequest?.proposedChanges ? (
              <>
                <pre>
                  {`Title: ${selectedRequest.proposedChanges.title || 'N/A'}`}
                  {`\nGenre: ${selectedRequest.proposedChanges.genre || 'N/A'}`}
                  {`\nRelease Date: ${selectedRequest.proposedChanges.release_date || 'N/A'}`}
                  {`\nDescription: ${selectedRequest.proposedChanges.description || 'N/A'}`}
                  {`\nPrice: $${selectedRequest.proposedChanges.price || 'N/A'}`}
                </pre>
                {selectedRequest.proposedChanges.game_img_url && (
                  <img
                    src={selectedRequest.proposedChanges.game_img_url}
                    alt="Original Game"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '200px',
                      marginTop: '10px',
                      borderRadius: '8px',
                    }}
                  />
                )}
              </>
            ) : (
              'No proposed changes available.'
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GameChangeRequests;
