// src/components/GameCard.tsx
import React, { useState } from 'react';
import { Card, Button, Modal, Input, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
import { API_BASE_URL } from '../config';
import { STRIPE_PK } from '../config';

// Initialize stripePromise with your public Stripe key
const stripePromise = loadStripe(`${STRIPE_PK}`);

const { Meta } = Card;

type GameCardProps = {
  id: number;
  title: string;
  genre: string;
  release_date: string;
  description: string;
  price: number;
  imgSrc: string;
};

const GameCard: React.FC<GameCardProps> = ({ id, title, description, price, imgSrc }) => {
  const navigate = useNavigate();
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [rentalId, setRentalId] = useState('');
  const [loading, setLoading] = useState(false);

  const [redirectEnabled, setRedirectEnabled] = useState(true);

  const handleCardClick = () => {
    if (redirectEnabled) {
      navigate(`/game/${title}`, { state: {id, title, description, price } });
    }
  };

  const checkAuth = async () => {
    const response = await fetch(`${API_BASE_URL}/api/v1/users/me`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
    });
    return response.status === 200;
  };

  const createTempUser = async (email: string) => {
    const response = await fetch(`${API_BASE_URL}/api/v1/register/temporary`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({"email": email}),
    });
    if (response.ok) {
      const data = await response.json();
      localStorage.setItem('authToken', data.access_token);
      return true;
    }
    if (response.status === 400) {
      message.error('User with such email already exists')
    }
    message.error('There was an error creating temporary account')
    return false;
  };

  const checkAndSetRental = async () => {
    const response = await fetch(`${API_BASE_URL}/api/v1/rentals/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
    });
    if (response.status >= 400) {
      message.error('Game is not available.');
      return null;
    }
    const rentalData = await response.json();
    setRentalId(rentalData.id);  // Store rental id
    return rentalData;
  };


  const handleBuy = async () => {

    setLoading(true);

    // Step 1: Check if the user is authenticated
    const isAuthenticated = await checkAuth();
    if (!isAuthenticated) {
      setIsEmailModalVisible(true);
      setLoading(false);
      return;
    }

    // Step 2: Check game availability and set rental to pending
    const rentalInfo = await checkAndSetRental();
    if (!rentalInfo) {
      setLoading(false);
      return;
    }

    // Show Stripe payment modal
    setIsPaymentModalVisible(true);
    setLoading(false);
  };

  const handleCreateUser = async () => {
    const success = await createTempUser(email);
    if (success) {
      setIsEmailModalVisible(false);
      message.success('Temporary user created. Please ensure to use valid email before proceeding with payment');
      handleBuy(); // Retry purchase after user creation
    }
  };

  return (
    <Card
      hoverable
      style={{ width: 240, margin: '16px' }}
      cover={<img alt={title} src={imgSrc} />}
      onClick={handleCardClick}
    >
      <Meta title={title} description={description} />
      <div style={{ marginTop: '10px' }}>
        <p>Price: ${price.toFixed(2)}</p>
      </div>
      <div style={{ marginTop: '10px', textAlign: 'center', }}>
        <Button
          type="primary"
          onClick={(e) => {
            e.stopPropagation();
            setRedirectEnabled(false);
            handleBuy();
          }}
          loading={loading}
        >
          Buy
        </Button>
      </div>

      {/* Temporary User Creation Modal */}
      <Modal
        title="Enter Your Email"
        visible={isEmailModalVisible}
        onOk={handleCreateUser}
        onCancel={() => setIsEmailModalVisible(false)}
      >
        <Input
          placeholder="Enter your email to proceed"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Modal>

      {/* Checkout Form Modal */}
      <Modal visible={isPaymentModalVisible} onCancel={() => setIsPaymentModalVisible(false)} footer={null}>
        <Elements stripe={stripePromise}>
          <CheckoutForm price={price} rentalId={rentalId.toString()} />
        </Elements>
      </Modal>
    </Card>
  );
};

export default GameCard;
